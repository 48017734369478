import type { PartialRecursive } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Trackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IContact } from './Contacts.types';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
@Trackable
export class Contact extends AbstractDocument<IContact> {
  readonly collections = {};
}

export class Contacts extends AbstractCollection<Contact, IContact> {
  static definitions = {
    _: {} as IContact,
  };

  static path = 'contacts';

  constructor(document: Supplier) {
    super(document.collection(Contacts.path), Contact);
  }

  static new<M extends typeof Contacts.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<IContact> = {};

    if (key !== '_' && key in Contacts.definitions) {
      result = (result[key as keyof Omit<typeof Contacts.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
