import type { PartialRecursive } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Trackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IAchievement } from './Achievements.types';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
@Trackable
export class Achievement extends AbstractDocument<IAchievement> {
  readonly collections = {};

  readonly translatable = (data: IAchievement): IAchievement['_translations'][string] => ({
    name: data.name,
  });
}

export class Achievements extends AbstractCollection<Achievement, IAchievement> {
  static definitions = {
    _: {} as IAchievement,
  };

  static path = 'achievements';

  constructor(document: Supplier) {
    super(document.collection(Achievements.path), Achievement);
  }

  static new<M extends typeof Achievements.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<IAchievement> = {};

    if (key !== '_' && key in Achievements.definitions) {
      result = (result[key as keyof Omit<typeof Achievements.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
