import { AbstractDocument, Timestampable, Trackable } from '../../../abstract/Document';
import { Suppliers } from '../../Suppliers';
import type { ISupplierAdmin } from './admin.types';

@Timestampable
@Trackable
export class AdminAdmin extends AbstractDocument<ISupplierAdmin> {
  readonly collections = {};

  /**
   * Returns whether additional areas for the supplier are defined.
   */
  get hasAdditionalAreas() {
    const id = this.reference.parent.parent.id;

    return Suppliers._.getById(id)
      .get(true)
      .then((data) => data?.additionalAreas != null && data?.additionalAreas.length > 0);
  }

  /**
   * Returns whether the pay per enquiry feature flag is set.
   */
  get payPerEnquiry() {
    return this.get(true).then((data) => data?.payPerEnquiry || false);
  }
}
