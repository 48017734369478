import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Timestampable, Trackable } from '../../abstract/Document';
import type { Supplier } from '../Suppliers';
import { AdminAdmin } from './Admin/admin';
import type { ISupplierAdmin } from './Admin/admin.types';
import { AdminChargebee } from './Admin/chargebee';
import type { ISupplierAdminChargebee } from './Admin/chargebee.types';
import { AdminLedger } from './Admin/ledger';
import type { ISupplierAdminLedger } from './Admin/ledger.types';
import { AdminScore } from './Admin/score';
import type { ISupplierAdminScore } from './Admin/score.types';
import { AdminStats } from './Admin/stats';
import type { ISupplierAdminStats } from './Admin/stats.types';
import { AdminStripe } from './Admin/stripe';
import type { ISupplierAdminStripe } from './Admin/stripe.types';
import type { WriteBatch } from 'firebase/firestore';

type Collections =
  | ISupplierAdmin
  | ISupplierAdminChargebee
  | ISupplierAdminLedger
  | ISupplierAdminScore
  | ISupplierAdminStats
  | ISupplierAdminStripe;

@Timestampable
@Trackable
export class Admin extends AbstractDocument<Collections> {
  readonly collections = {};
}

export class Admins extends AbstractCollection<Admin, Collections> {
  static definitions = {
    _: {} as Collections,
  };

  static path = 'admin';

  constructor(document: Supplier) {
    super(document.collection(Admins.path), Admin);
  }

  get admin() {
    return this.getById('admin');
  }

  get chargebee() {
    return this.getById('chargebee');
  }

  get ledger() {
    return this.getById('ledger');
  }

  get score() {
    return this.getById('score');
  }

  get stats() {
    return this.getById('stats');
  }

  get stripe() {
    return this.getById('stripe');
  }

  getById(key: 'admin'): AdminAdmin & AdminAdmin['collections'];
  getById(key: 'chargebee'): AdminChargebee & AdminChargebee['collections'];
  getById(key: 'ledger'): AdminLedger & AdminLedger['collections'];
  getById(key: 'score'): AdminScore & AdminScore['collections'];
  getById(key: 'stats'): AdminStats & AdminStats['collections'];
  getById(key: 'stripe'): AdminStripe & AdminStripe['collections'];

  getById(key: 'admin' | 'chargebee' | 'ledger' | 'score' | 'stats' | 'stripe', batch?: WriteBatch) {
    let document;

    switch (key) {
      case 'admin':
        document = new (AbstractCollection as any)(this.reference, AdminAdmin);
        break;

      case 'chargebee':
        document = new (AbstractCollection as any)(this.reference, AdminChargebee);
        break;

      case 'ledger':
        document = new (AbstractCollection as any)(this.reference, AdminLedger);
        break;

      case 'score':
        document = new (AbstractCollection as any)(this.reference, AdminScore);
        break;

      case 'stats':
        document = new (AbstractCollection as any)(this.reference, AdminStats);
        break;

      case 'stripe':
        document = new (AbstractCollection as any)(this.reference, AdminStripe);
        break;

      default:
        throw new Error('Unknown document ID.');
    }

    return document.getById(key, batch);
  }

  getAdmin() {
    return this.admin.get();
  }

  getChargebee(): Promise<ISupplierAdminChargebee | undefined> {
    return this.chargebee.get();
  }

  getLedger() {
    return this.ledger.get();
  }

  getScore() {
    return this.score.get();
  }

  getStats() {
    return this.stats.get();
  }

  getStripe() {
    return this.stripe.get();
  }
}
