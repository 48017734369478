import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Trackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IVideo } from './Videos.types';

@Identifiable
@Timestampable
@Trackable
export class Video extends AbstractDocument<IVideo> {
  readonly collections = {};

  readonly translatable = (data: IVideo): IVideo['_translations'][string] => ({
    label: data.label,
  });
}

export class Videos extends AbstractCollection<Video, IVideo> {
  static definitions = {
    _: {} as IVideo,
  };

  static path = 'videos';

  constructor(document: Supplier) {
    super(document.collection(Videos.path), Video);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
