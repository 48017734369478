import type { PartialRecursive } from '../../../abstract/_';
import { AbstractCollection } from '../../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Trackable } from '../../../abstract/Document';
import { Suppliers } from '../../Suppliers';
import type { ISupplierAdminLedger } from '../Admin/ledger.types';
import { type ISupplierLedgerTransaction, ISupplierProduct, ITransactionOperation } from '../Admin/ledger/Transactions.types';
import type { Wedding } from '../Weddings';
import type { ISupplierWedding } from '../Weddings.types';
import type { ISupplierWeddingEnquiry } from './Enquiries.types';
import { increment, type PartialWithFieldValue, runTransaction, serverTimestamp, type WithFieldValue } from 'firebase/firestore';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
@Trackable
export class Enquiry extends AbstractDocument<ISupplierWeddingEnquiry> {
  readonly collections = {};

  // readonly translatable = (data: ISupplierWeddingEnquiry): ISupplierWeddingEnquiry['_translations'][string] => ({
  //   message: data.message,
  // });

  get supplier() {
    return Suppliers._.getById(this.reference.parent.parent.parent.parent.id);
  }

  get wedding() {
    return this.supplier.Weddings.getById(this.reference.parent.parent.id);
  }

  isLocked() {
    return this.wedding.isLocked();
  }

  isRevealed() {
    return this.wedding.isRevealed();
  }

  /**
   * Reveals an enquiry, spending product balance if needed.
   *
   * @param user User ID
   */
  async reveal(user: string) {
    return await runTransaction(this.wedding.reference.firestore, async (transaction) => {
      let locked = false;

      const [supplierAdmin, weddingEnquiry] = await Promise.all([
        transaction.get(this.supplier.Admins.admin.reference).then((snapshot) => snapshot.data()),
        transaction.get(this.wedding.reference).then((snapshot) => snapshot.data()),
      ]);

      if (weddingEnquiry?.flags?.isPayPerEnquiry === true) {
        locked = true;

        if (weddingEnquiry?.flags?.revealed === true) {
          locked = false;
        } else if (weddingEnquiry?.source === 'widget') {
          locked = false;
        }
      }

      if (supplierAdmin?.payPerEnquiry !== true) {
        locked = false;
      }

      if (locked === true) {
        const ledgerRef = this.supplier.Admins.ledger.reference;
        const ledgerSnapshot = await transaction.get(ledgerRef);
        const ledgerTransactionRef = this.supplier.Admins.ledger.Transactions.push().reference;
        const balance = ledgerSnapshot.data()?.balance?.[ISupplierProduct.ENQUIRIES] ?? 0;

        if (balance < 1) {
          throw new Error(`Insufficient balance for product '${ISupplierProduct.ENQUIRIES}'.`);
        }

        const ledgerData: PartialWithFieldValue<ISupplierAdminLedger> = {
          balance: {
            [ISupplierProduct.ENQUIRIES]: increment(-1),
          },
          updatedAt: serverTimestamp(),
        };

        const ledgerTransactionData: WithFieldValue<ISupplierLedgerTransaction> = {
          balance: balance + -1,
          createdAt: serverTimestamp(),
          id: ledgerTransactionRef.id,
          metadata: {
            enquiry: this.id,
            wedding: this.reference.parent.parent.id,
          },
          operation: ITransactionOperation.SPENT,
          product: ISupplierProduct.ENQUIRIES,
          units: -1,
          updatedAt: serverTimestamp(),
          user,
        };

        transaction.set(ledgerRef, ledgerData, {
          merge: true,
        });

        transaction.set(ledgerTransactionRef, ledgerTransactionData, {
          merge: true,
        });
      }

      const weddingEnquiryData: PartialWithFieldValue<ISupplierWedding> = {
        flags: {
          revealed: true,
        },
        timestamps: {
          revealed: weddingEnquiry?.timestamps?.revealed ?? serverTimestamp(),
        },
      };

      transaction.set(this.wedding.reference, weddingEnquiryData, { merge: true });

      return {
        ...weddingEnquiry,
        ...weddingEnquiryData,
      } as ISupplierWedding;
    });
  }
}

export class Enquiries extends AbstractCollection<Enquiry, ISupplierWeddingEnquiry> {
  static definitions = {
    _: {} as ISupplierWeddingEnquiry,
  };

  static path = 'enquiries';

  constructor(document: Wedding) {
    super(document.collection(Enquiries.path), Enquiry);
  }

  static new<M extends typeof Enquiries.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<ISupplierWeddingEnquiry> = {};

    if (key !== '_' && key in Enquiries.definitions) {
      result = (result[key as keyof Omit<typeof Enquiries.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.parent.parent.id);
  }

  get wedding() {
    return this.supplier.Weddings.getById(this.reference.parent.id);
  }
}
