import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Trackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IPhoto } from './Photos.types';
import { limit, orderBy, where } from 'firebase/firestore';
import { values } from 'ramda';

@Identifiable
@Timestampable
@Trackable
export class Photo extends AbstractDocument<IPhoto> {
  readonly collections = {};

  readonly translatable = (data: IPhoto): IPhoto['_translations'][string] => ({
    label: data.label,
  });
}

export class Photos extends AbstractCollection<Photo, IPhoto> {
  static definitions = {
    _: {} as IPhoto,
  };

  static path = 'photos';

  constructor(document: Supplier) {
    super(document.collection(Photos.path), Photo);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }

  async getByOrder(order: number) {
    const photos = await this.query([where('order', '==', order)]).get();

    return values(photos)[0];
  }

  async getFirst() {
    const photos = await this.query([orderBy('order'), limit(1)]).get();

    return values(photos)[0];
  }
}
