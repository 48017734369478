import type { PartialRecursive } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Trackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { ISupplierWedding } from './Weddings.types';
import { Enquiries } from './Weddings/Enquiries';
import { where } from 'firebase/firestore';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
@Trackable
export class Wedding extends AbstractDocument<ISupplierWedding> {
  readonly collections = {
    Enquiries: new Enquiries(this),
  };

  get supplier() {
    return Suppliers._.getById(this.reference.parent.parent.id);
  }

  /**
   * Returns whether this enquiry is currently locked or not, depending on whether:
   *
   * 1) The supplier pay-per-enquiry feature flag is enabled, and
   * 2) Any enquiry originating from the same wedding has already been revealed.
   */
  async isLocked() {
    const data = await this.get(true);

    if ((await this.isPayPerEnquiry(data)) !== true) {
      return false;
    }

    if ((await this.isRevealed(data)) === true) {
      return false;
    }

    return this.supplier.Admins.admin.payPerEnquiry;
  }

  /**
   * True if an enquiry has been sent while supplier had payPerEnquiry.
   */
  async isPayPerEnquiry(data?: ISupplierWedding) {
    if (data == null) {
      data = await this.get(true);
    }

    return data?.flags?.isPayPerEnquiry ?? false;
  }

  async isRevealed(data?: ISupplierWedding) {
    if (data == null) {
      data = await this.get(true);
    }

    return data?.flags?.revealed === true || data?.source === 'widget';
  }
}

export class Weddings extends AbstractCollection<Wedding, ISupplierWedding> {
  static definitions = {
    _: {} as ISupplierWedding,
  };

  static path = 'weddings';

  constructor(document: Supplier) {
    super(document.collection(Weddings.path), Wedding);
  }

  static new<M extends typeof Weddings.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<ISupplierWedding> = {
      guests: {
        estimate: 0,
      },
      partners: ['', ''],
    };

    if (key !== '_' && key in Weddings.definitions) {
      result = (result[key as keyof Omit<typeof Weddings.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }

  async getByWeddingId(weddingId: string) {
    return await this.query([where('wedding', '==', weddingId)]).get();
  }
}
